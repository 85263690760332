import { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Button } from '@vakantiesnl/components/src/__LEGACY__/atoms/Button';
import { Card } from '@vakantiesnl/components/src/__LEGACY__/atoms/Card';
import { Icon } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';
import { Link } from '@vakantiesnl/components/src/__LEGACY__/atoms/Link';
import { Price } from '@vakantiesnl/components/src/atoms/Price';
import { useLinkCardUrl } from '@vakantiesnl/components/src/utils/contentful';
import ImageComponent from '@vakantiesnl/image-component';
import { ContentfulImageParams } from '@vakantiesnl/image-component/src/loaders/contentful';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { LinkCard as LinkCardProps } from '@vakantiesnl/types';

import useStyles from './LinkCard.style';

type LinkWrapperProps = {
	children: ReactNode;
	variantClassName?: string;
	target: '_blank' | '_self';
	route: string;
	onClick?: VoidFunction;
	className?: string;
};

const contentfulParams: ContentfulImageParams = { fm: 'webp' };

const LinkWrapper: FC<LinkWrapperProps> = ({ className, variantClassName, onClick, children, route, target }) => {
	const { classes: styles, cx } = useStyles();

	return (
		<Link
			href={route}
			target={target}
			rel={'noopener noreferrer'}
			className={cx(styles.container, variantClassName, className)}
			onClick={onClick}
		>
			{children}
		</Link>
	);
};

export const LinkCard: FC<LinkCardProps> = (props) => {
	const {
		variant,
		title,
		imgSrc,
		onClick,
		target,
		price,
		withButton = true,
		verticalImgSrc,
		withVerticalImg = false,
		className,
	} = props;
	const { classes: styles, cx } = useStyles();
	const theme = useTheme();
	const microCopies = useMicroCopyContext();
	const variantClass: string =
		variant === 'extraLarge' ? styles.extraLarge : variant === 'large' ? styles.large : styles.small;
	const href = useLinkCardUrl(props);

	if (withVerticalImg && !verticalImgSrc) return null;

	return (
		<LinkWrapper
			className={className}
			route={href}
			target={target}
			variantClassName={variantClass}
			onClick={onClick}
		>
			<Card noBorder={true}>
				<div className={cx(styles.wrapper, variantClass)}>
					<ImageComponent
						useLoader="Contentful"
						contentfulParams={contentfulParams}
						src={withVerticalImg && verticalImgSrc ? verticalImgSrc : imgSrc}
						alt={title}
						fill={true}
						sizes={
							variant === 'large'
								? /** Has to support full-width sized images on content pages, which is 1168 px.
									 * For smaller screens than that, be responsive to the viewport. */
									`(max-width:${theme.breakpoints.values.xl}px) 100vw, 1168px`
								: `(max-width: ${theme.breakpoints.values.sm}px) 80vw, ${withVerticalImg ? 394 : 290}px`
						}
						key={href}
					/>
					{price && (
						<div className={styles.priceWrapper}>
							<Icon className={styles.priceBackground} type="shape8plus" />
							<Price
								className={styles.price}
								price={price}
								prefix={microCopies['common.perPersonFrom']}
							/>
						</div>
					)}
					{!withButton && (
						<Typography className={styles.title} variant="caption">
							{title}
						</Typography>
					)}
					{withButton && (
						<div className={cx(styles.buttonContainer, variantClass)}>
							<Button
								className={variantClass}
								variant="primary"
								title={title}
								iconRight="vaknlSleekArrowRight"
								largeIcon={true}
							/>
						</div>
					)}
				</div>
			</Card>
		</LinkWrapper>
	);
};
